import './types/react-types';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import { createContext, useCallback, useEffect, useState } from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { Alert, AlertColor, AppBar, Box, Drawer, Grid } from '@mui/material';
import CreateButton from './components/navigation/CreateButton';
import Login from './Login';
import Navigation from './components/navigation/Navigation';
import CDToolbar from './components/CDToolbar';
import ApiService from './ApiService';
import { AuthConfig } from './types/AuthConfig';
import AppLoading from './components/AppLoading';
import AppFooter from './components/AppFooter';
import AppHead from './AppHead';
import ReactGA4 from 'react-ga4';
import ApiInterceptor from './components/ApiInterceptor';
import { Agency } from './types/Agency';
import { InfoMessage, InfoMessageProps } from './components/InfoMessage';
import { Permission, USER_GROUP_ADMIN, USER_GROUP_ADVERTISER, USER_GROUP_AGENCY } from './types/User';
import {
    isRouteErrorResponse,
    Outlet,
    ScrollRestoration,
    useLocation,
    useNavigate,
    useRouteError,
} from 'react-router-dom';
import { GlobalInfoMessageComponent, InfoMessageProvider } from './components/GlobalInfoMessage';
import { ReleaseNotesDisplay } from './pages/releaseNotes/ReleaseNotesDisplayComponent';
import { AdvertiserContext, AdvertiserContextValue } from './AdvertiserContext';
import { Dealer } from './types/Dealer';
import Utils from './components/Utils';

export class UserContextValue {
    userId?: number | null | undefined = undefined;
    username: string = '';
    name?: string = '';
    email?: string = '';
    groups: string[] = [];
    agencyId: number | null = null;
    agency?: Agency;
    permissions: Permission[] = [];
    adminContext?: UserContextValue;
    organization?: string = '';

    isAdmin(): boolean {
        return this.getGroup().indexOf(USER_GROUP_ADMIN) > -1;
    }

    isAdvertiser(): boolean {
        return this.getGroup().indexOf(USER_GROUP_ADVERTISER) > -1;
    }

    isAgency(): boolean {
        return this.getGroup().indexOf(USER_GROUP_AGENCY) > -1;
    }

    getGroup(): string[] {
        return this.groups;
    }

    hasPermission(permissionCode: string): boolean {
        return this.permissions.filter((permission) => permission.permissionCode === permissionCode).length > 0;
    }
}

export const UserContext = createContext({
    userContext: new UserContextValue(),
    setUserContext: (
        _userId: number | null | undefined,
        _userName: string | null,
        _name: string | undefined,
        _group: string | undefined,
        _agencyId: number | null,
        _organization: string | undefined,
        _agency?: Agency
    ) => {},
    refreshUserContext: () => {},
});

export const AgencyContext = createContext<Agency | null>(null);

Hub.listen('auth', (data) => {
    if (data && data.payload && data.payload.event === 'login') {
        console.log(data);
    }
});

export default function App() {
    const [amplifyConfigured, setAmplifyConfigured] = useState(false);
    const [agencyContext, setAgencyContext] = useState<Agency | null>(null);
    const [systemError, setSystemError] = useState(false);

    const loadApp = useCallback(() => {
        let host = window.location.hostname;

        ApiService.getAgencyByHost(host)
            .then((response) => {
                let agency = response.data;
                agency.name = agency.name + ' - Amazon Ads Portal';
                setAgencyContext(agency);
            })
            .catch(() => {
                let agency = new Agency();
                agency.name = '[cognition]';
                setAgencyContext(agency);
            });

        ApiService.getAppConfig()
            .then(function (response) {
                let authConfig: AuthConfig = response.data;
                Amplify.configure({
                    aws_cognito_region: authConfig.region,
                    aws_user_pools_id: authConfig.userPoolId,
                    aws_user_pools_web_client_id: authConfig.appClientId,
                });
                Amplify.Logger.LOG_LEVEL = 'INFO';
                setAmplifyConfigured(true);
                setSystemError(false);

                if (authConfig.gaTrackingID !== '') {
                    ReactGA4.initialize(authConfig.gaTrackingID, {
                        gaOptions: {
                            debug_mode: true,
                        },
                    });
                }

                sessionStorage.setItem('trialfireTrackingId', authConfig.trialfireTrackingId);
            })

            .catch((error) => {
                console.log(error);
                setSystemError(true);
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('trialfireTrackingId');
                setTimeout(() => {
                    loadApp();
                }, 10000);
            });
    }, []);

    useEffect(() => {
        if (!amplifyConfigured) {
            localStorage.removeItem('X-COGNITION-USER');
            loadApp();
        }
    }, [amplifyConfigured, loadApp]);

    useEffect(() => {
        if (agencyContext?.timezone) {
            Utils.setDefaultTimezone(agencyContext.timezone);
        }
    }, [agencyContext]);

    if (systemError) {
        return <AppLoading systemError={systemError} />;
    }

    return amplifyConfigured ? (
        <Authenticator.Provider>
            <AgencyContext.Provider value={agencyContext}>
                <AppContent />
            </AgencyContext.Provider>
        </Authenticator.Provider>
    ) : null;
}

function AppContent() {
    const [userContext, setUserContext] = useState<UserContextValue>();
    const [advertiserContext, setAdvertiserContext] = useState<AdvertiserContextValue>(new AdvertiserContextValue());

    const [infoMessage, setInfoMessage] = useState<InfoMessageProps>({
        message: null,
    });
    const location = useLocation();
    const navigate = useNavigate();
    const routeError = useRouteError();
    const [fullWidth, setFullWidth] = useState(false);
    const [isLegacyLayout, setIsLegacyLayout] = useState<boolean>(true);

    const { authStatus, user } = useAuthenticator((context) => [context.authStatus, context.user]);

    // A flag that's set when sessionStorage has been updated with a valid
    // token. The app content won't render until the token is set. This is
    // necessary because some content, like ApiInterceptor, reads the token
    // value from sessionStorage, so it shouldn't be rendered until the
    // token is available.
    const [haveToken, setHaveToken] = useState<boolean>(false);

    function showInfoMessage(alertColor: AlertColor, message: string) {
        setInfoMessage({
            message: message,
            severity: alertColor,
            onClose: () => {
                setInfoMessage({ message: null });
            },
        });
    }

    function refresh() {
        refreshContext(userContext);
    }

    function refreshContext(userData: UserContextValue | undefined) {
        if (userData) {
            ApiService.getCurrentUser()
                .then((response) => {
                    userData.userId = response.data.id;
                    userData.agencyId = response.data.agencyId;
                    userData.permissions = response.data.permissions;
                    userData.agency = response.data.agency;
                    userData.organization = response.data.organization;
                    setUserContext(userData);
                    setAdvertiserContext({ dealer: null, agencyId: userData.agencyId });
                })
                .catch(() => {});
        }
    }

    function setUser(
        userId: number | null | undefined,
        userName: string | null,
        name: string | undefined,
        group: string | undefined,
        agencyId: number | null,
        organization: string | undefined,
        agency?: Agency
    ) {
        if (userName === null) {
            setUserContext(userContext?.adminContext);
            localStorage.removeItem('X-COGNITION-USER');
            return;
        }
        let newContext = new UserContextValue();
        if (userContext?.adminContext) {
            newContext.adminContext = userContext.adminContext;
        } else {
            newContext.adminContext = userContext;
        }
        localStorage.setItem('X-COGNITION-USER', userName);
        newContext.userId = userId;
        newContext.username = userName;
        newContext.name = name;
        newContext.groups = group ? [group] : [];
        newContext.agencyId = agencyId;
        newContext.agency = agency;
        newContext.organization = organization;
        setUserContext(newContext);
        setAdvertiserContext({ dealer: null, agencyId: agencyId });
        showInfoMessage(
            'success',
            `Logged in as “${newContext.name}”. To return to your account click the User name and select “Return to Admin View”`
        );
        navigate('/');
    }

    const logout = useCallback(async () => {
        await Auth.signOut();
        navigate('/');
    }, [navigate]);

    // Update the session token when the authStatus changes
    useEffect(() => {
        if (authStatus === 'authenticated') {
            Auth.currentSession()
                .then((session) => {
                    let token = session.getAccessToken().getJwtToken();
                    sessionStorage.removeItem('token');
                    sessionStorage.setItem('token', token);
                    setHaveToken(true);
                })
                .catch(() => {});
        } else {
            setHaveToken(false);
            sessionStorage.removeItem('token');
        }
    }, [authStatus]);

    // Update or clear the user context when the authStatus or user changes
    useEffect(() => {
        if (authStatus === 'authenticated') {
            if (ReactGA4.isInitialized) {
                ReactGA4.send({
                    hitType: 'pageview',
                    page: window.location.pathname,
                });

                let userTypeIdentifier = userContext?.getGroup()[0];

                ReactGA4.gtag('set', 'user_properties', {
                    userType: userTypeIdentifier,
                    organization: userContext?.organization,
                });
            }

            if (!userContext) {
                user.getUserAttributes((_error, attributes) => {
                    if (attributes) {
                        let groups = [];
                        let session = user.getSignInUserSession();
                        if (session) {
                            groups = session.getAccessToken().payload['cognito:groups'] || [];
                            if (groups.length === 0) {
                                logout();
                                return;
                            }
                        }
                        let attributeMap = new Map(attributes.map((key) => [key.Name, key.Value] as [string, string]));
                        let fullName = attributeMap.has('name') ? attributeMap.get('name') : user.getUsername();
                        let userData = new UserContextValue();
                        userData.username = user.getUsername();
                        userData.name = fullName;
                        userData.email = attributeMap.get('email');
                        userData.groups = groups;

                        refreshContext(userData);
                    }
                });
            }
        } else {
            setUserContext(undefined);
        }
    }, [authStatus, logout, user, userContext]);

    useEffect(() => {
        let locationPathname: string = location.pathname;

        if (locationPathname.includes('/campaign/v2')) {
            locationPathname = '/campaign/v2/*';
        }

        switch (locationPathname) {
            case '/campaign/v2/*':
            case '/campaign/v2/create':
                setIsLegacyLayout(false);
                break;

            default:
                setIsLegacyLayout(true);
                break;
        }
    }, [location.pathname]);

    if (authStatus === 'authenticated' && userContext && haveToken) {
        return (
            <AdvertiserContext.Provider
                value={{
                    advertiserContext: advertiserContext,
                    setDealer: (dealer: Dealer | null) => {
                        setAdvertiserContext((prev) => {
                            return { ...prev, dealer: dealer };
                        });
                    },
                    setAdvertiserContext: (dealer, agencyId) => {
                        setAdvertiserContext({ dealer: dealer, agencyId: agencyId });
                    },
                }}
            >
                <UserContext.Provider
                    value={{
                        userContext: userContext,
                        setUserContext: setUser,
                        refreshUserContext: refresh,
                    }}
                >
                    <InfoMessageProvider>
                        <>
                            <AppHead />
                            <GlobalInfoMessageComponent />
                            <ReleaseNotesDisplay />
                            <InfoMessage {...infoMessage} />
                            <ApiInterceptor />
                            <Box id="appBox" className="App" sx={{ display: 'flex' }}>
                                <AppBar
                                    variant="top"
                                    sx={{
                                        zIndex: '1201',
                                    }}
                                >
                                    <CDToolbar
                                        logout={logout}
                                        showMenu={() => {
                                            if (!fullWidth) setFullWidth(!fullWidth);
                                        }}
                                        toggleMenu={() => setFullWidth(!fullWidth)}
                                    />
                                </AppBar>

                                <Drawer
                                    variant="persistent"
                                    open={true}
                                    className="App-sidebar"
                                    sx={{
                                        width: fullWidth ? '240px' : '88px',
                                    }}
                                >
                                    <div style={{ marginTop: '64px', height: 'calc(100vh - 152px)' }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignContent: 'center',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                mt: '24px',
                                                mb: '24px',
                                            }}
                                        >
                                            <CreateButton />
                                        </Box>

                                        <Navigation forceOpen={() => setFullWidth(true)} fullWidth={fullWidth} />
                                    </div>
                                </Drawer>
                                <Grid
                                    container
                                    sx={{
                                        minHeight: 'calc(100vh - 100px)',
                                    }}
                                >
                                    <Grid
                                        id="app-grid"
                                        sx={{
                                            position: 'relative',
                                            top: '64px',
                                        }}
                                        item
                                        marginTop={isLegacyLayout ? '16px' : 0}
                                        marginBottom={isLegacyLayout ? '16px' : 0}
                                        marginLeft={isLegacyLayout ? (fullWidth ? '0px' : '24px') : 0}
                                        marginRight={isLegacyLayout ? '24px' : 0}
                                        xs={12}
                                    >
                                        {routeError && isRouteErrorResponse(routeError) && (
                                            <Alert severity="error">{routeError.error?.message}</Alert>
                                        )}
                                        <ScrollRestoration />
                                        <Outlet />
                                        <AppFooter />
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    </InfoMessageProvider>
                </UserContext.Provider>
            </AdvertiserContext.Provider>
        );
    }
    return <Login />;
}
