import { useCallback, useState } from 'react';

import { Campaign, CampaignAdGroupInventorySource } from '../types/Campaign';
import {
    CampaignInventorySourceDefaultSetting,
    CampaignInventorySourceTypeDefaultSetting,
    CampaignDefaultSetting,
    CampaignDefaultSettingCriteria,
} from '../types/CampaignDefaultSetting';
import ApiService from '../ApiService';
import Utils from '../components/Utils';

export interface CampaignDefaultSettings {
    fetchCampaignDefaultSettings: (campaignDefaultSettingCriteria: CampaignDefaultSettingCriteria) => void;
    isFetchingCampaignDefaultSettings: boolean;
    setIsFetchingCampaignDefaultSettings: (isFetchingCampaignDefaultSettings: boolean) => void;

    campaignDefaultSettings: CampaignDefaultSetting[];
    setCampaignDefaultSettings: (campaignDefaultSettings: CampaignDefaultSetting[]) => void;
    applyCampaignDefaultSettings: (campaign: Campaign, campaignDefaultSettings: CampaignDefaultSetting[]) => Campaign;

    getCampaignSettings: () => Partial<Campaign>;
    getCampaignInventorySources: () => string[];
    getCampaignInventorySourceTypes: () => string[];

    toCampaignAdGroupInventorySources: (
        adGroupInventorySources: CampaignAdGroupInventorySource[]
    ) => CampaignAdGroupInventorySource[];

    campaignDefaultSettingCriteria: CampaignDefaultSettingCriteria;
    setCampaignDefaultSettingCriteria: (campaignDefaultSettingCriteria: CampaignDefaultSettingCriteria) => void;
}

export function useCampaignDefaultSettings(): CampaignDefaultSettings {
    const [campaignDefaultSettingCriteria, setCampaignDefaultSettingCriteria] =
        useState<CampaignDefaultSettingCriteria>({
            mediaType: '',
            goalType: '',
        });
    const [campaignDefaultSettings, setCampaignDefaultSettings] = useState<CampaignDefaultSetting[]>([]);
    const [isFetchingCampaignDefaultSettings, setIsFetchingCampaignDefaultSettings] = useState<boolean>(false);

    const fetchCampaignDefaultSettings = useCallback(
        Utils.debounce((criteria: CampaignDefaultSettingCriteria) => {
            if (criteria?.isDirty) {
                if (criteria.mediaType && criteria.goalType) {
                    setIsFetchingCampaignDefaultSettings(true);

                    ApiService.getCampaignDefaultSettings(criteria)
                        .then((response) => {
                            if (response?.data?.length > 0) {
                                setCampaignDefaultSettings([...response.data]);
                            }

                            setCampaignDefaultSettingCriteria({
                                ...campaignDefaultSettingCriteria,
                                isDirty: false,
                            });
                        })
                        .catch(() => {
                            // no-op
                        })
                        .finally(() => {
                            setIsFetchingCampaignDefaultSettings(false);
                        });
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, 500),
        []
    );

    const applyCampaignDefaultSettings = (
        campaign: Campaign,
        campaignDefaultSettings: CampaignDefaultSetting[]
    ): Campaign => {
        if (campaignDefaultSettings) {
            campaignDefaultSettings.forEach((campaignDefaultSetting: CampaignDefaultSetting) => {
                if (campaignDefaultSetting.enabled === true) {
                    if (campaignDefaultSetting['budgetCapType'] !== null) {
                        campaign.budgetCapType = campaignDefaultSetting['budgetCapType'];
                    }

                    if (typeof campaignDefaultSetting['budgetAmount'] === 'number') {
                        campaign.budget = campaignDefaultSetting['budgetAmount'];
                    }

                    if (typeof campaignDefaultSetting['budgetCapAmount'] === 'number') {
                        campaign.budgetCapAmount = campaignDefaultSetting['budgetCapAmount'];
                    }

                    if (campaignDefaultSetting['budgetCapRecurrenceTimePeriod'] !== null) {
                        campaign.budgetCapRecurrenceTimePeriod =
                            campaignDefaultSetting['budgetCapRecurrenceTimePeriod'];
                    }

                    if (campaignDefaultSetting['automateBudgetAllocation'] !== null) {
                        campaign.automateBudgetAllocation = campaignDefaultSetting['automateBudgetAllocation'];
                    }

                    if (campaignDefaultSetting['targetKpi'] !== null) {
                        campaign.targetKpi = campaignDefaultSetting['targetKpi'];
                    }

                    if (campaignDefaultSetting['targetKpiDisabled'] !== null) {
                        campaign.targetKpiDisabled = campaignDefaultSetting['targetKpiDisabled'];
                    }

                    if (campaignDefaultSetting['bidStrategy'] !== null) {
                        campaign.bidStrategy = campaignDefaultSetting['bidStrategy'];
                    }

                    if (campaignDefaultSetting['frequencyMaxImpressions'] !== null) {
                        campaign.frequencyMaxImpressions = campaignDefaultSetting['frequencyMaxImpressions'];
                    }

                    if (campaignDefaultSetting['frequencyType'] !== null) {
                        campaign.frequencyType = campaignDefaultSetting['frequencyType'];
                    }

                    if (campaignDefaultSetting['frequencyTimeUnitCount'] !== null) {
                        campaign.frequencyTimeUnitCount = campaignDefaultSetting['frequencyTimeUnitCount'];
                    }

                    if (campaignDefaultSetting['frequencyTimeUnit'] !== null) {
                        campaign.frequencyTimeUnit = campaignDefaultSetting['frequencyTimeUnit'];
                    }
                }
            });
        }

        return campaign;
    };

    const getCampaignSettings = (): Partial<Campaign> => {
        let partial: Partial<Campaign> = {};

        if (campaignDefaultSettings) {
            campaignDefaultSettings.forEach((campaignDefaultSetting: CampaignDefaultSetting) => {
                partial = {
                    ...{
                        budgetCapType: campaignDefaultSetting['budgetCapType'],
                        budget: campaignDefaultSetting['budgetAmount'] ?? undefined,
                        budgetCapAmount: campaignDefaultSetting['budgetCapAmount'] ?? undefined,
                        budgetCapRecurrenceTimePeriod: campaignDefaultSetting['budgetCapRecurrenceTimePeriod'],
                        automateBudgetAllocation: campaignDefaultSetting['automateBudgetAllocation'],
                        targetKpi: campaignDefaultSetting['targetKpi'] ?? undefined,
                        targetKpiDisabled: campaignDefaultSetting['targetKpiDisabled'],
                        bidStrategy: campaignDefaultSetting['bidStrategy'],
                        frequencyMaxImpressions: campaignDefaultSetting['frequencyMaxImpressions'] ?? undefined,
                        frequencyType: campaignDefaultSetting['frequencyType'],
                        frequencyTimeUnitCount: campaignDefaultSetting['frequencyTimeUnitCount'] ?? undefined,
                        frequencyTimeUnit: campaignDefaultSetting['frequencyTimeUnit'],
                    },
                };
            });
        }

        return partial;
    };

    const getCampaignInventorySources = (): string[] => {
        let inventorySources: string[] = [];

        if (campaignDefaultSettings) {
            campaignDefaultSettings.forEach((campaignDefaultSetting: CampaignDefaultSetting) => {
                campaignDefaultSetting.inventorySourceDefaultSettings.forEach(
                    (inventorySourceDefaultSetting: CampaignInventorySourceDefaultSetting) => {
                        if (!inventorySources.includes(inventorySourceDefaultSetting.dspInventoryId)) {
                            inventorySources.push(inventorySourceDefaultSetting.dspInventoryId);
                        }
                    }
                );
            });
        }

        return inventorySources;
    };

    const getCampaignInventorySourceTypes = (): string[] => {
        let inventorySourceTypes: string[] = [];

        if (campaignDefaultSettings) {
            campaignDefaultSettings.forEach((campaignDefaultSetting: CampaignDefaultSetting) => {
                campaignDefaultSetting.inventorySourceTypeDefaultSettings.forEach(
                    (inventorySourceTypeDefaultSetting: CampaignInventorySourceTypeDefaultSetting) => {
                        if (!inventorySourceTypes.includes(inventorySourceTypeDefaultSetting.dspInventorySourceType)) {
                            inventorySourceTypes.push(inventorySourceTypeDefaultSetting.dspInventorySourceType);
                        }
                    }
                );
            });
        }

        return inventorySourceTypes;
    };

    const toCampaignAdGroupInventorySources = (
        adGroupInventorySources: CampaignAdGroupInventorySource[]
    ): CampaignAdGroupInventorySource[] => {
        const inventorySources: string[] = getCampaignInventorySources();

        inventorySources.forEach((dspInventoryId: string) => {
            if (
                !adGroupInventorySources.find(
                    (x: CampaignAdGroupInventorySource) => x.dspInventoryId === dspInventoryId
                )
            ) {
                adGroupInventorySources.push({
                    dspInventoryId: dspInventoryId,
                });
            }
        });

        return adGroupInventorySources;
    };

    return {
        fetchCampaignDefaultSettings,
        isFetchingCampaignDefaultSettings,
        setIsFetchingCampaignDefaultSettings,
        campaignDefaultSettings,
        setCampaignDefaultSettings,
        applyCampaignDefaultSettings,

        getCampaignSettings,
        getCampaignInventorySources,
        getCampaignInventorySourceTypes,

        toCampaignAdGroupInventorySources,

        campaignDefaultSettingCriteria,
        setCampaignDefaultSettingCriteria,
    };
}
