import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
// import { useNavigate } from 'react-router-dom';

import { Campaign } from '../../../types/Campaign';
import CampaignDuplicateDataGrid from './CampaignDuplicateDataGrid';
import Column from '../../../components/Column';
import Row from '../../../components/Row';

type CampaignDuplicateDialogProps = {
    campaign: Campaign;
    duplicateCampaigns: Campaign[];
    open: boolean;
    onClose?: () => void;
    onCancel?: () => void;
    onConfirm?: () => void;
    onView?: (campaign: Campaign) => void;
};

export default function CampaignDuplicateDialog(props: CampaignDuplicateDialogProps) {
    const { duplicateCampaigns } = props;

    // const navigate = useNavigate();

    // const handleCampaignConfirm = () => {
    //     if (campaign) {
    //         navigate(`${campaign.id}/edit`);
    //     }
    // };

    const handleClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    const handleCancel = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    const handleOk = () => {
        if (props.onConfirm) {
            props.onConfirm();
        }
    };

    const handleView = (campaign: Campaign) => {
        if (props.onView) {
            props.onView(campaign);
        }
    };

    return (
        <Dialog fullWidth={true} maxWidth="md" open={props.open} onClose={handleClose}>
            <DialogTitle>Duplicate Campaign(s) Detected</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ pt: 3, pb: 5 }}>
                <Column>
                    <Alert severity="warning">
                        Similar campaigns detected. Please click <strong>Review</strong> to modify, or click{' '}
                        <strong>Ignore & Continue</strong> to proceed.
                    </Alert>

                    <Column gap={1}>
                        <Typography variant="subtitle2" color="text.primary">
                            Possible Duplicate Campaign(s)
                        </Typography>

                        <CampaignDuplicateDataGrid rows={duplicateCampaigns} onView={handleView} />
                    </Column>
                </Column>
            </DialogContent>
            <DialogActions sx={{ px: 3, py: 2 }}>
                <Row gap={1} justifyContent="flex-end" alignItems="center">
                    <Button variant="outlined" onClick={handleCancel}>
                        Cancel
                    </Button>

                    <Button variant="contained" color="primary" onClick={handleOk}>
                        Ignore Duplicate(s) & Continue
                    </Button>
                </Row>
            </DialogActions>
        </Dialog>
    );
}
