import { useCallback, useEffect } from 'react';
import { AppBar, Button, Box, Grid, Paper } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

import { Campaign } from '../../../../types/Campaign';
import { CampaignWizardContextValue } from '../../../../hooks/useCampaignWizard';

import AppLoader from '../../../../components/AppLoader';
import CampaignDuplicateValidator from '../CampaignDuplicateValidator';
import CampaignWizardCreativeStep from './CampaignWizardCreativeStep';
import CampaignWizardGeographyStep from './CampaignWizardGeographyStep';
import CampaignWizardReviewStep from './CampaignWizardReviewStep';
import CampaignWizardSetupStep from './CampaignWizardSetupStep';
import CampaignWizardStepNav from './CampaignWizardStepNav';
import Row from '../../../../components/Row';

export type CampaignWizardStepProps = {
    campaign: Campaign;
    onChange(value: Partial<Campaign>): void;
};

export type CampaignWizardProps = {
    campaignWizard: CampaignWizardContextValue;
};

export default function CampaignWizard(props: CampaignWizardProps) {
    const { campaignWizard } = props;

    const navigate = useNavigate();

    const {
        $campaignDuplicateValidator,
        $loader,

        campaign,
        fetchCampaign,
        handleSubmit,
        ignoreDuplicates,
        isCompletedCampaign,
        isDraftCampaign,
        saveInProgress,
        setCampaign,
        setIgnoreDuplicates,
        stepper,
    } = campaignWizard;
    const { isLoading = false } = $loader || {};

    const handleCampaignChange = useCallback(
        (value: Partial<Campaign>) => {
            setCampaign((prev: Campaign) => {
                // console.log({ ...prev, ...value });
                return { ...prev, ...value };
            });
        },
        [setCampaign]
    );

    const handleIgnoreCampaignDuplicates = () => {
        setIgnoreDuplicates(true);
    };

    const handleCancelCampaignWizard = (): void => {
        navigate('/campaignManager');
    };

    const handlePreviousStepCampaignWizard = (): void => {
        stepper.toPreviousStep();
    };

    const handleNextStepCampaignWizard = (): void => {
        stepper.toNextStep();
    };

    useEffect(() => {
        fetchCampaign();
    }, [fetchCampaign]);

    return (
        <form onSubmit={handleSubmit}>
            <AppLoader loading={isLoading} />

            <Box sx={{ flexGrow: 1 }}>
                <Grid container>
                    <Grid item xs={3} sx={{ px: 3 }}>
                        <AppBar
                            position="sticky"
                            color="transparent"
                            sx={{
                                backgroundColor: (theme) => theme.palette.common.white,
                                top: 64 + 72 + 24,
                            }}
                        >
                            <Paper elevation={0}>
                                <CampaignWizardStepNav stepper={stepper} />
                            </Paper>
                        </AppBar>
                    </Grid>
                    <Grid
                        item
                        xs={9}
                        sx={{
                            backgroundColor: blueGrey['50'],
                            borderWidth: 0,
                            borderLeftWidth: 1,
                            borderStyle: 'solid',
                            borderColor: (theme) => theme.palette.grey['300'],
                            p: 3,
                            minHeight: '100vh',
                        }}
                    >
                        <Box>
                            {stepper.activeStep === 0 && (
                                <CampaignWizardSetupStep campaign={campaign} onChange={handleCampaignChange} />
                            )}

                            {stepper.activeStep === 1 && (
                                <CampaignWizardGeographyStep campaign={campaign} onChange={handleCampaignChange} />
                            )}

                            {stepper.activeStep === 2 && (
                                <CampaignWizardCreativeStep campaign={campaign} onChange={handleCampaignChange} />
                            )}

                            {stepper.activeStep === 3 && (
                                <CampaignWizardReviewStep campaign={campaign} onChange={handleCampaignChange} />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <AppBar
                            position="fixed"
                            color="transparent"
                            sx={{
                                backgroundColor: (theme) => theme.palette.common.white,
                                // height: 72,
                                top: 'auto',
                                bottom: 0,
                            }}
                        >
                            <Paper
                                elevation={0}
                                sx={{
                                    borderWidth: '0px',
                                    borderTopWidth: '1px',
                                    borderStyle: 'solid',
                                    borderColor: (theme) => theme.palette.grey['300'],
                                    paddingX: 4,
                                    paddingY: 2,
                                }}
                            >
                                <Row alignItems="center" justifyContent="flex-end">
                                    {stepper.activeStep === 0 && (
                                        <Button variant="outlined" color="primary" onClick={handleCancelCampaignWizard}>
                                            Cancel
                                        </Button>
                                    )}

                                    {stepper.hasPreviousStep() && (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            disabled={!stepper.canPreviousStep()}
                                            onClick={handlePreviousStepCampaignWizard}
                                        >
                                            Back
                                        </Button>
                                    )}

                                    {stepper.hasNextStep() && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={!stepper.canNextStep()}
                                            onClick={handleNextStepCampaignWizard}
                                        >
                                            Next
                                        </Button>
                                    )}

                                    {stepper.hasNextStep() === false && (
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={isCompletedCampaign || saveInProgress}
                                        >
                                            {isDraftCampaign ? 'Submit' : 'Update'}
                                        </Button>
                                    )}
                                </Row>
                            </Paper>
                        </AppBar>
                    </Grid>
                </Grid>

                {$campaignDuplicateValidator && (
                    <CampaignDuplicateValidator
                        campaignDuplicateValidator={$campaignDuplicateValidator}
                        campaign={campaign}
                        ignoreDuplicates={ignoreDuplicates}
                        onIgnoreDuplicates={handleIgnoreCampaignDuplicates}
                    />
                )}
            </Box>
        </form>
    );
}
